<template>
  <div class="page-box">
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
          <a-form-model-item label="订单号" prop="orderNo">
            <a-input v-model="queryRef.orderNo" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="订单类型" prop="orderType">
            <a-select v-model="queryRef.orderType" placeholder='请选择' style="width: 120px">
              <a-select-option v-for='item in orderTypes' :key='item.value' :value="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="商品名称" prop="productName">
            <a-input v-model="queryRef.productName" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="收货人" prop="receiverName">
            <a-input v-model="queryRef.receiverName" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="收货人电话" prop="receiverPhone">
            <a-input v-model="queryRef.receiverPhone" placeholder="请输入" />
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="下单时间" prop="orderTime">
            <a-range-picker v-model="orderTime" />
          </a-form-model-item>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(0)">
            今
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(1)">
            昨
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(7)">
            近7天
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(30)">
            近30天
          </a-button>
        </a-row>
        <a-form-model-item class="screen-btns">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <base-tabs :tabs="orderStateList" :active="queryRef.orderState" @change="handleStateChange" />
    <order-table is-batch-sell :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :loading="loadingRef" :orderState="queryRef.orderState" @update="getTableData" @paginationChange="handleTableChange" />
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from '@vue/composition-api'
import { orderStates, orderTypes } from '@/utils/constants'
import moment from 'moment'
import { order } from '@/api'
import OrderTable from '@/components/OrderTable'

const orderStateList = [
  { title: '全部', value: '' },
  // 去掉待成团
  ...orderStates.slice(0, -1).map((x) => ({ title: x.name, value: x.value })),
  { title: '售后中', value: '6' }
]

const columns = [
  {
    title: '商品',
    dataIndex: 'name'
  },
  {
    title: '单价/数量',
    dataIndex: 'price'
  },
  {
    title: '售后',
    dataIndex: 'service'
  },
  {
    title: '实收金额(元)',
    dataIndex: 'realAmount'
  },
  {
    title: '订单状态',
    dataIndex: 'orderState'
  },
  {
    title: '操作',
    dataIndex: 'operation'
  }
]

export default {
  name: 'PageBatchSellOrder',

  components: {
    OrderTable
  },

  setup (props, { root }) {
    const formRef = ref(null)
    const orderTime = ref([])
    watch(
      () => orderTime.value,
      (data) => {
        if (data.length) {
          queryRef.value.orderBeginTime = `${moment(orderTime.value[0]).format(
            'YYYY-MM-DD'
          )} 00:00:00`
          queryRef.value.orderEndTime = `${moment(orderTime.value[1]).format(
            'YYYY-MM-DD'
          )} 23:59:59`
        } else {
          queryRef.value.orderBeginTime = ''
          queryRef.value.orderEndTime = ''
        }
      }
    )

    const queryRef = ref({
      orderNo: '',
      productName: '',
      orderState: '',
      orderBeginTime: '',
      orderEndTime: '',
      receiverName: '',
      receiverPhone: '',
      orderType: undefined
    })
    onMounted(() => {
      queryRef.value.orderState = root.$route.query.state || ''
      getTableData()
      root.$bus.$on('service-update', getTableData)
      root.$bus.$on('delivery-update', getTableData)
    })
    onUnmounted(() => {
      root.$bus.$off('service-update', getTableData)
      root.$bus.$off('delivery-update', getTableData)
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0
    })
    const loadingRef = ref(false)
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } =
        queryRef.value.orderState === '6'
          ? await order.getBatchSellOrderAfter({ ...queryRef.value, ...pageRtv.value })
          : await order.getBatchSellOrder({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false
      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      orderTime.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange (current) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleStateChange (state) {
      if (state === queryRef.value.orderState) return
      queryRef.value.orderState = state
      handleSearch()
    }

    function handleDateShortcut (day) {
      orderTime.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment()
      ]
    }

    return {
      orderTypes,
      orderStateList,
      columns,
      formRef,
      orderTime,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      getTableData,
      handleSearch,
      handleReset,
      handleStateChange,
      handleTableChange,
      handleDateShortcut
    }
  }
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
</style>
